<template>
    <div class="container management pb-5" v-cloak>
        <b-overlay
            :show="loading"
            rounded="sm"
            no-wrap
            z-index="1000"
        ></b-overlay>
        <div class="row">
            <div class="col-12 px-3">
                <button class="btn btn-common btn-back" @click="back">
                    Home
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-3">
                <h3 class="title">Licenses Management</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-3">
                <div class="instructions p-3">
                    <div>
                        Input one of the items below to query your licenses:
                        <ul class="mb-0">
                            <li>
                                Use PayPal email address to query all your
                                licenses.
                            </li>
                            <li>Use License key to query the one license.</li>
                            <li>
                                Use Activated device SN to query the one
                                license.
                            </li>
                            <li>
                                Use crypto address to query all the correlative
                                licenses.
                            </li>
                        </ul>
                        <!-- <span class="font-weight-bold">ONLY</span> through
            <span class="font-weight-bold">PayPal email</span> you can query all your licenses, this method needs verification code sent to that email address.-->
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 px-3">
                <form>
                    <div class="d-flex flex-column align-items-stretch">
                        <button
                            class="btn btn-common btn-secondary my-2"
                            @click.stop.prevent="queryAllLicenses"
                        >
                            Query all my licenses
                        </button>

                        <template>
                            <input
                                type="text"
                                class="form-control my-2"
                                placeholder="PayPal Email / License / Device SN / Crypto Address"
                                v-model="text"
                            />
                        </template>

                        <button
                            v-show="showQueryButton"
                            class="btn btn-common btn-green my-2"
                            @click.stop.prevent="queryLicenses"
                        >
                            Query
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div
            class="row"
            v-show="showQueryButton"
            v-if="queried && licenses.length > 0"
        >
            <b-tabs v-model="tabIndex" class="col-12 mt-3" content-class="mt-3">
                <b-tab title="Available" active></b-tab>
                <b-tab title="Expired"></b-tab>
            </b-tabs>
        </div>

        <div
            class="row"
            v-show="showQueryButton"
            v-if="queried && licenses.length > 0"
        >
            <div class="col-12 little-toolbar">
                <div
                    class="d-flex flex-row justify-content-between align-items-center"
                >
                    <div class="row">
                        <div class="col-12 py-2">
                            <b-pagination
                                class="my-0"
                                v-if="total > perPage"
                                v-model="currentPage"
                                :total-rows="total"
                                :per-page="perPage"
                                size="sm"
                            ></b-pagination>
                        </div>
                        <div
                            class="col-12 d-flex flex-row justify-content-start align-items-center"
                        >
                            <div class="mr-2" v-if="selectToRenew">
                                <input
                                    type="checkbox"
                                    class=""
                                    v-model="selectedAll"
                                />
                                <span class="ml-2">Select All</span>
                            </div>
                            <button
                                class="btn btn-common"
                                @click="selectToRenew = !selectToRenew"
                                v-if="!selectToRenew"
                            >
                                Select to Renew
                            </button>
                            <button
                                v-else
                                class="btn btn-common mr-2"
                                @click="selectToRenew = !selectToRenew"
                            >
                                Close
                            </button>
                            <button
                                class="btn btn-common btn-renew"
                                v-if="selectToRenew"
                                :disabled="
                                    !selectedToRenew ||
                                        selectedToRenew.length <= 0
                                "
                                @click="renew"
                            >
                                Renew
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row licenses" v-show="showQueryButton">
            <template v-if="queried && licenses.length > 0">
                <div
                    v-for="item in licenses"
                    :key="item.id"
                    class="col-12 col-md-6 px-3 py-2"
                >
                    <div
                        class="license-card position-relative"
                        :class="{
                            'ios-pro': item.license.plan === 'IOS_PRO',
                            'ios-basic': item.license.plan === 'IOS_BASIC',
                            'macos-basic': item.license.plan === 'MACOS_BASIC',
                            'macos-pro': item.license.plan === 'MACOS_PRO',
                            default: ![
                                'IOS_BASIC',
                                'IOS_PRO',
                                'MACOS_BASIC',
                                'MACOS_PRO'
                            ].includes(item.license.plan)
                        }"
                    >
                        <div class="property-title">Plan</div>
                        <div>
                            <div>{{ item.license.plan }}</div>
                        </div>
                        <div class="property-title">License Key</div>
                        <div>
                            <div
                                class="license-key"
                                v-on:click="copyText(item.license.license_key)"
                            >
                                {{ item.license.license_key }}
                            </div>
                        </div>
                        <div class="property-title">Status</div>
                        <div>
                            <template
                                v-if="isExpired(item.license.expire_time)"
                                >{{ tran("Expired") }}</template
                            >
                            <template v-else>{{
                                tran(item.license.status)
                            }}</template>
                        </div>
                        <div class="property-title">Validity</div>
                        <div>{{ item.license.validity }} Months</div>
                        <div class="property-title">Expires Time</div>
                        <div>
                            <template v-if="item.license.expire_time">{{
                                timeFormat(item.license.expire_time)
                            }}</template>
                            <template
                                v-if="
                                    item.activation &&
                                        item.activation.activated_time &&
                                        !item.license.expires
                                "
                                >{{ tran("forever") }}</template
                            >
                        </div>
                        <div class="property-title">Purchased Time</div>
                        <div>
                            <template v-if="item.license">{{
                                timeFormat(item.license.create_time)
                            }}</template>
                        </div>

                        <div class="property-title">Activated Device</div>
                        <div
                            class="d-flex flex-row justify-content-between align-items-center"
                        >
                            <div
                                v-if="
                                    item.license.status == 'NORMAL' &&
                                        !isExpired(item.license.expire_time)
                                "
                                class="d-flex flex-row justify-content-start align-items-center"
                            >
                                <div v-if="item.activation">
                                    {{ item.activation.device_sign }}
                                </div>
                                <div
                                    v-if="
                                        item.activation &&
                                            item.activation.device_sign ===
                                                deviceId
                                    "
                                    class="ml-1"
                                    style="color: red"
                                >
                                    {{ tran("current device") }}
                                </div>
                            </div>
                        </div>
                        <div v-if="selectToRenew" class="card-checkbox">
                            <input
                                type="checkbox"
                                class=""
                                v-model="selectedToRenew"
                                :value="item.license.license_key"
                            />
                        </div>
                        <div
                            class="options"
                            v-if="!isExpired(item.license.expire_time)"
                        >
                            <b-dropdown variant="button" no-caret right>
                                <template slot="button-content">
                                    <svg
                                        class="bi bi-gear"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 16 16"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 014.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 01-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 011.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 012.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 012.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 011.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 01-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 018.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 001.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 00.52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 00-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 00-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 00-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 00-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 00.52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 001.255-.52l.094-.319z"
                                            clip-rule="evenodd"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 5.754a2.246 2.246 0 100 4.492 2.246 2.246 0 000-4.492zM4.754 8a3.246 3.246 0 116.492 0 3.246 3.246 0 01-6.492 0z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </template>

                                <template
                                    v-if="
                                        item.license.status == 'NORMAL' &&
                                            !isExpired(item.license.expire_time)
                                    "
                                >
                                    <b-dropdown-text
                                        style="width: 240px"
                                        v-if="
                                            isActivationLocked(
                                                item.license.activation_lock_to
                                            )
                                        "
                                        >{{
                                            tran("One activation a day!")
                                        }}</b-dropdown-text
                                    >

                                    <template v-else>
                                        <b-dropdown-item
                                            v-if="
                                                deviceId &&
                                                    !isCurrentDeviceActivated
                                            "
                                            class
                                            v-on:click="
                                                doActivateDevice(
                                                    item.license.license_key,
                                                    deviceId
                                                )
                                            "
                                            >{{
                                                tran("Activate Current Device")
                                            }}</b-dropdown-item
                                        >
                                        <b-dropdown-item
                                            class
                                            v-on:click="
                                                doActivateDevice(
                                                    item.license.license_key
                                                )
                                            "
                                            >{{
                                                tran("Activate Another Device")
                                            }}</b-dropdown-item
                                        >
                                    </template>
                                </template>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
            </template>

            <div
                v-if="queried && licenses.length === 0"
                class="col-12 not-license-found py-3"
            >
                {{ tran("No license found!") }}
            </div>
        </div>
    </div>
</template>

<script>
import config from "../../config";
import axios from "axios";
import moment from "moment";
import i18n from "../i18n";
import { mapGetters } from "vuex";

function isEmail(text) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text).toLowerCase());
}

function isLicenseKey(text) {
    var re = /^[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}$/;
    return re.test(String(text));
}

function isDeviceSN(text) {
    var re = /^[A-Za-z0-9]{11,12}$/;
    return re.test(String(text));
}

function isCryptoAddress(text) {
    var re = /^0x[A-Za-z0-9]+$/;
    return re.test(String(text));
}

let lang = "en";

export default {
    name: "Manage",
    data() {
        return {
            lang: this.$store.state.language,
            deviceId: this.$store.state.currentDeviceId,
            paid_from_address: this.$route.query.paid_from_address,

            text: null,
            textType: null,
            code: null,
            showQueryButton: false,
            queried: false,
            isCurrentDeviceActivated: false,
            licenses: [],
            loading: false,

            tabIndex: 0,

            currentPage: 1,
            perPage: 20,

            selectToRenew: false,
            selectedToRenew: [],
            selectedAll: false,
            periodToRenew: 1
        };
    },
    beforeMount() {
        // Should be at first
        if (!!this.paid_from_address) {
            this.text = this.paid_from_address;
        } else if (!!this.$route.params.email) {
            this.text = this.$route.params.email;
        } else if (!!this.deviceId) {
            this.text = this.deviceId;
        }
        this.updateType();
        if (this.text) {
            this.queryLicenses();
        }
    },
    computed: {
        ...mapGetters({
            user: "getUser",
            isLoggedIn: "isLoggedIn"
        })
    },
    watch: {
        text: function(newVal, oldVal) {
            if (newVal == oldVal) return;
            this.text = newVal;
            this.updateType();
        },
        tabIndex: function(newVal, oldVal) {
            this.currentPage = 1;
            this.queryLicenses();
        },
        currentPage: function(newVal, oldVal) {
            this.queryLicenses();
        },
        selectedAll: function(newVal, oldVal) {
            if (newVal === true) {
                this.selectedToRenew = this.licenses.map(
                    l => l.license.license_key
                );
            } else {
                this.selectedToRenew = [];
            }
        }
    },
    methods: {
        back() {
            this.$router.push({ name: "home" });
        },
        updateType() {
            if (isEmail(this.text)) {
                this.textType = "email";
                this.showQueryButton = true;
            } else if (isLicenseKey(this.text)) {
                this.textType = "license";
                this.showQueryButton = true;
            } else if (isDeviceSN(this.text)) {
                this.textType = "sn";
                this.showQueryButton = true;
            } else if (isCryptoAddress(this.text)) {
                this.textType = "address";
                this.showQueryButton = true;
            } else {
                this.textType = null;
            }
        },
        tran(key) {
            return i18n[this.lang][key];
        },
        timeFormat(t) {
            return moment
                .utc(t)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
        },
        checkCurrentDevice() {
            let self = this;

            if (!self.deviceId) return;

            self.loading = true;

            const url = `${config.server}/api/v1/license?id=${self.deviceId}`;

            axios
                .get(url)
                .then(function(response) {
                    const { validated } = response.data;
                    self.isCurrentDeviceActivated = validated;
                })
                .catch(function(error) {
                    // alert(error.response.data);
                })
                .finally(function() {
                    self.queried = true;
                    self.loading = false;
                });
        },
        queryLicenses() {
            const self = this;

            if (!self.textType) {
                return;
            }

            if (self.textType == "email" && !self.isLoggedIn) {
                self.$router.push("/login");

                self.$notify({
                    group: "main",
                    type: "error",
                    text: "You need to login!"
                });
                return;
            }

            let by;
            switch (self.textType) {
                case "email":
                    by = "PAYPAL_EMAIL";
                    break;
                case "license":
                    by = "LICENSE_KEY";
                    break;
                case "sn":
                    by = "ACTIVATED_DEVICE";
                    break;
                case "address":
                    by = "CRYPTO_ADDRESS";
                    break;
                default:
                    return;
            }

            let value = self.text;
            if (!value) {
                return;
            }
            value = value.trim();
            if (value == "") {
                return;
            }

            self.loading = true;

            const url = `${config.server}/api/v1/licenses/query?page=${
                self.currentPage
            }&limit=${self.perPage}&expired=${
                self.tabIndex === 1 ? true : false
            }`;

            axios
                .get(url, {
                    params: {
                        by,
                        value
                    }
                })
                .then(function(response) {
                    const { items, total } = response.data;
                    self.licenses = items;
                    self.total = total;
                })
                .catch(function(error) {
                    // alert(error.response ? error.response.data : error)
                })
                .finally(function() {
                    self.queried = true;
                    self.loading = false;
                });
        },
        doActivateDevice(licenseKey, deviceSign) {
            const self = this;

            if (!licenseKey) {
                licenseKey = prompt(
                    this.tran("License Key you want to use:"),
                    ""
                );
                // If the user clicks "OK", the input value is returned. If the user clicks "cancel", null is returned.
                // If the user clicks OK without entering any text, an empty string is returned.
                if (!licenseKey) {
                    return;
                }
            }

            if (!deviceSign) {
                deviceSign = prompt(
                    this.tran(
                        "Serial Number of the device you want to activate:"
                    ),
                    ""
                );
                if (!deviceSign) {
                    return;
                }
            } else {
                if (
                    !confirm(
                        "Are you sure to activate current device with this license?"
                    )
                ) {
                    return;
                }
            }

            self.loading = true;

            const url = `${config.server}/api/v1/licenses/activate`;
            axios
                .get(url, {
                    params: {
                        license_key: licenseKey.trim(),
                        device_sign: deviceSign.trim()
                    }
                })
                .then(function() {
                    alert("Activation successful!");
                    if (self.deviceId) {
                        self.checkCurrentDevice();
                    }
                    self.queryLicenses("ACTIVATED_DEVICE");
                })
                .catch(function(error) {
                    // alert(error.response.data);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        isExpired: function(t) {
            if (!t) return false;
            const expireTime = moment(t);
            const utcNow = moment()
                .utc()
                .toDate();
            return utcNow > expireTime;
        },
        isActivationLocked(activation_lock_to) {
            if (this.isLoggedIn) return false;
            if (!activation_lock_to) return false;
            const activationLockToTime = moment.utc(activation_lock_to);
            const utcNow = moment().utc();
            return utcNow < activationLockToTime;
        },
        renew() {
            this.$router.push({
                name: "renew",
                params: { selectedToRenew: this.selectedToRenew }
            });
        },
        queryAllLicenses() {
            let self = this;

            if (!self.isLoggedIn) {
                self.$router.push({
                    name: "login",
                    params: { email: self.text ? self.text.trim() : null }
                });
                self.$notify({
                        group: "main",
                        type: "info",
                        text: "You need to login!"
                    });
            } else {
                self.text = self.user.email;
                self.textType = "email";
                self.queryLicenses();
            }
        }
    }
};
</script>

<style scoped lang="scss">
.instructions {
    font-size: 13px;

    ul {
        font-weight: 600;
    }
}

.management {
    input[type="text"],
    input[type="submit"] {
        height: 55px;
    }
}

.licenses {
    .license-card {
        padding: 10px;
        border-radius: 5px;
        display: grid;
        grid-template-columns: 100px auto;
        gap: 0 5px;

        > * {
            font-size: 13px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        .property-title {
            font-family: inherit;
        }

        .license-key {
            font-weight: bold;
        }

        &.ios-basic {
            // border: 2px solid #144cdb;
            box-shadow: 0 0 2px 0px #144cdb;
            background-image: linear-gradient(to top, #5badf1, #2060fe);
            color: #ffffff;

            .bi-gear {
                filter: invert(100%) sepia(2%) saturate(37%) hue-rotate(291deg)
                    brightness(111%) contrast(98%);
            }
        }

        &.ios-pro {
            // border: 2px solid #d6491a;
            box-shadow: 0 0 2px 0px #d6491a;
            background-image: linear-gradient(to top, #ff8a00, #da1b60);
            color: #ffffff;

            .bi-gear {
                filter: invert(100%) sepia(2%) saturate(37%) hue-rotate(291deg)
                    brightness(111%) contrast(98%);
            }
        }

        &.macos-basic {
            // border: 2px solid #d6491a;
            box-shadow: 0 0 2px 0px #415d43;
            background-image: linear-gradient(to top, #b9e769, #0db39e);
            color: #ffffff;

            .bi-gear {
                filter: invert(100%) sepia(2%) saturate(37%) hue-rotate(291deg)
                    brightness(111%) contrast(98%);
            }
        }

        &.macos-pro {
            // border: 2px solid #d6491a;
            box-shadow: 0 0 2px 0px #b5852c;
            background-image: linear-gradient(to top, #ffd100, #f7af29);
            color: #ffffff;

            .bi-gear {
                filter: invert(100%) sepia(2%) saturate(37%) hue-rotate(291deg)
                    brightness(111%) contrast(98%);
            }
        }

        &.default {
            background-color: #eeeeee;
            border: 1px solid #e1e1e1;
            // box-shadow: 0 0 50px 0px #e1e1e1;
            color: #333333;

            .bi-gear {
                filter: invert(100%) sepia(2%) saturate(37%) hue-rotate(291deg)
                    brightness(111%) contrast(98%);
            }
        }
    }

    .not-license-found {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        padding: 10px;
    }

    .options {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }

    .card-checkbox {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 15px;
    }
}

.little-toolbar {
    > div {
        padding: 15px;
        background-color: #f5f5f5;
        border-radius: 5px;
    }
}
</style>
